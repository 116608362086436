$blueGray: (
  200: #e2e8f0,
  300: #cbd5e1,
);

$coolGray: (
  50: #f9fafb,
  100: #f3f4f6,
);

$fuchsia: (
  50: #fdf4ff,
);

$green: (
  500: #22c55e,
  600: #32cd32,
  700: #3DD35E
);

$primary: (
  100: #e0e7ff,
  500: #6366f1,
);

$red: (
  100: #FF3D00,
  500: #ef4444,
);

$orange: (
  100: #FFCB29,
);

$blue: (
  100: #215CF4
);

$gray: (
  100: #CBD5E1
);

$text: (
  'default': #1e293b,
  'secondary': #94a3b8,
  'hint': #64748b,
);

$icon_colors: (
  'default': #CBD5E1,
  'committee': #3DD35E,
  'limeGreen': #32cd32
);

$border: #e2e8f0;

$ghostwhite: #f9fafd;
