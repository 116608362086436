@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import '~styles/variables';
@import '~styles/tools';
@import '~styles/override';
@import '~styles/colors';
@import '~styles/mixins';
@import '~styles/calendar';
@import '~styles/theme';
@import '~styles/scroll';

.committees-app {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;

  com-calendar,
  com-call,
  com-protocols,
  com-committee-documents {
    flex-grow: 1;
  }

  com-form-block {
    flex-grow: 1;

    & > div {
      border-color: map_get($blueGray, 200);

      com-form-group,
      com-form-group-item,
      com-form-input,
      com-form-select,
      com-form-multiselect,
      com-form-toggle,
      com-form-datepicker,
      com-form-autocomplete {
        flex-grow: 1;
      }

      .mat-form-field {
        &.mat-form-field-appearance-fill {
          .mat-form-field-wrapper {
            margin-bottom: 0;

            .mat-form-field-flex {
              min-height: 32px !important;

              .mat-form-field-infix {
                min-height: 32px;

                .mat-chip-list {
                  margin: 12px 4px 12px -8px;

                  .mat-chip-list-wrapper {
                    row-gap: 4px;

                    .mat-chip {
                      white-space: nowrap;
                    }
                  }
                }

                .mat-input-element {
                  padding: 0;

                  &.mat-chip-input {
                    margin-left: 12px;
                  }

                  &[type='number'] {
                    -moz-appearance: textfield;
                  }

                  &::-webkit-outer-spin-button,
                  &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }

                  &::placeholder {
                    color: map_get($text, 'secondary') !important;
                  }

                  &::after {
                    content: none;
                  }
                }
              }

              .mat-form-field-suffix {
                .mat-datepicker-toggle {
                  .mat-icon-button {
                    width: 24px;
                    height: 24px;
                    min-width: 24px;
                    min-height: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .mat-table {
    box-shadow: none;
    width: 100%;

    .mat-header-row {
      background-color: map_get($coolGray, 50);

      .mat-header-cell {
        @include text-base(600);
      }
    }

    .mat-row {
      height: 72px;

      &:hover {
        background-color: map_get($coolGray, 50);
        cursor: pointer;
      }

      .mat-cell {
        @include text-base(400);

        .default-avatar {
          @include text-lg(500);
          color: map_get($text, 'hint');
          background-color: map_get($fuchsia, 50);
        }

        .default-icon {
          background-color: map_get($text, 'hint');
        }

        .active {
          color: map_get($primary, 500);
          cursor: pointer;
          width: fit-content;
        }

        .hint {
          color: map_get($text, 'secondary');
        }
      }
    }
  }

  .mat-tooltip-panel {
    .mat-tooltip {
      max-width: fit-content;
      font-size: 14px;
      margin: 0;
    }
  }

  .mat-tab-header {
    padding: 16px 0;
    box-shadow: 4px 4px 8px 0 map_get($coolGray, 100);
  }

  .mat-paginator {
    z-index: 3;
  }

  .mat-tab-labels {
    .mat-tab-label {
      &.mat-tab-disabled {
        .mat-tab-label-content {
          color: map_get($blueGray, 300);
        }
      }
    }
  }

  .mat-radio-container {
    transform: scale(0.75);
  }

  .color-limeGreen {
    color: map_get($green, 600) !important;
  }

  .color-orange {
    color: map_get($orange, 100) !important;
  }

  .color-gray {
    color: #9caabd !important;
  }

  .color-red {
    color: map_get($red, 100) !important;
  }

  .bg-limeGreen {
    background-color: map_get($green, 600) !important;
  }

  .bg-ghostwhite {
    background-color: $ghostwhite;
  }

  mat-icon {
    &.icon-committee {
      color: map_get($icon_colors, 'default');
      &.icon-active {
        color: map_get($icon_colors, 'committee');
      }
    }
  }

  .mat-checkbox {
    &.invalid {
      .mat-checkbox-frame {
        border-color: map_get($red, 500);
      }
    }
  }

  .mat-button-toggle-group {
    width: fit-content;
    background-color: map_get($coolGray, 100);
    border-radius: 9999px;
    border: 1px solid map_get($blueGray, 200) !important;

    &.mat-form-field-invalid {
      border: 1px solid map_get($red, 100) !important;
    }

    &.mat-form-field-invalid {
      border: 1px solid map_get($red, 100) !important;
    }

    .mat-button-toggle {
      background-color: transparent;

      &.mat-button-toggle-checked {
        background-color: white;

        &:hover {
          background-color: transparent;
        }
      }
    }

    &.content-lineHeight {
      .mat-button-toggle {
        .mat-button-toggle-button {
          .mat-button-toggle-label-content {
            line-height: 30.4px;
          }
        }
      }
    }
  }

  .mat-expansion-panel-header-title {
    margin: 0;
  }

  quill-editor {
    &.ql-fixer {
      .ql-toolbar {
        border: 0;
      }
      .ql-container {
        border-top: 1px solid #ccc !important;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
    }

    &.invalid {
      .ql-container {
        border: 1px solid map_get($red, 500) !important;
      }
    }

    .ql-toolbar {
      border-radius: 0;
    }

    .ql-container {
      display: flex;
      flex-direction: column;
      min-height: 0;
      max-height: unset;

      .ql-editor {
        flex-grow: 1;
        padding: 16px;
        line-height: 24px;
        word-break: break-word;

        &:before {
          font-style: normal;
          font-size: 14px;
        }
      }

      .ql-editable-blot {
        background-color: map_get($blueGray, 200);
        padding: 2px 12px;
        border-radius: 16px;
      }
    }
  }
}

.com-perfect-scrollbar {
  @include scroll;
}

.mat-drawer-content {
  @include scroll;
}

.mat-select-panel {
  @include scroll;
}

.com-role-form {
  .mat-dialog-container {
    padding: 24px 16px 24px 24px;
  }
}

.agreement-dialog {
  .mat-dialog-container {
    padding: 0 !important;
  }
}

.com-tooltip-full-text {
  word-break: break-all !important;
  white-space: normal !important;
}

.mat-autocomplete-panel {
  @include scroll;
}
