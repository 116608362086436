.committees-app {
  /* ----------------------------------------------------------------------------------------------------- */
  /*  @ Perfect scrollbar overrides
/* ----------------------------------------------------------------------------------------------------- */
  .ps {
    position: relative;

    &:hover,
    &.ps--focus,
    &.ps--scrolling-x,
    &.ps--scrolling-y {
      > .ps__rail-x,
      > .ps__rail-y {
        opacity: 1;
      }
    }

    > .ps__rail-x,
    > .ps__rail-y {
      z-index: 99999;
    }

    > .ps__rail-x {
      height: 14px;
      background: transparent !important;
      transition: none !important;

      &:hover,
      &:focus,
      &.ps--clicking {
        opacity: 1;

        .ps__thumb-x {
          height: 10px;
        }
      }

      .ps__thumb-x {
        background: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.15);
        height: 6px;
        transition: height 225ms cubic-bezier(0.25, 0.8, 0.25, 1);
      }
    }

    > .ps__rail-y {
      width: 14px;
      background: transparent !important;
      transition: none !important;
      left: auto !important;

      &:hover,
      &:focus,
      &.ps--clicking {
        opacity: 1;

        .ps__thumb-y {
          width: 10px;
        }
      }

      .ps__thumb-y {
        background: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.15);
        width: 6px;
        transition: width 225ms cubic-bezier(0.25, 0.8, 0.25, 1);
      }
    }
  }
}
