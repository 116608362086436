@import '~styles/colors';

@mixin text-base($font-weight) {
  font-weight: $font-weight;
  font-size: 14px;
  line-height: 20px;
}

@mixin text-sm($font-weight) {
  font-weight: $font-weight;
  font-size: 12px;
  line-height: 16px;
}

@mixin text-lg($font-weight) {
  font-weight: $font-weight;
  font-size: 16px;
  line-height: 20px;
}

@mixin text-xl($font-weight) {
  font-weight: $font-weight;
  font-size: 18px;
  line-height: 24px;
}

@mixin text-2xl($font-weight) {
  font-weight: $font-weight;
  font-size: 24px;
  line-height: 32px;
}

@mixin text-4xl($font-weight) {
  font-weight: $font-weight;
  font-size: 32px;
  line-height: 32px;
}
