/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind components;
@tailwind utilities;

@layer components {
  .committees-app {
    .list-dash {
      list-style-type: none;

      li {
        &::before {
          content: '- ';
        }
      }
    }
  }
}
