@import '~styles/colors';

.committees-app {
  mat-paginator {
    &.tab-overrided {
      margin-left: -24px;
      margin-right: -24px;
      margin-bottom: -24px;
      bottom: -24px;
      box-shadow: 0px 0px 4px 0px;
    }
  }
  .no-margin {
    mat-form-field.mat-form-field-appearance-fill {
      .mat-form-field-wrapper {
        margin-bottom: 0;
      }
    }
  }

  snack-bar-container.mat-snack-bar-container {
    background-color: map_get($coolGray, 50) !important;
    color: map_get($text, 'default') !important;
  }

  mat-tab-group.mat-tab-group {
    .mat-tab-header {
      padding-top: 0;
      box-shadow: none;
      & > .mat-tab-header-pagination {
        box-shadow: none;
      }

      .mat-tab-label-container {
        padding: 0;
      }
    }
    .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content {
      padding: 16px 0;
    }
  }

  mat-button-toggle-group {
    &.leading-8 {
      .mat-button-toggle {
        .mat-button-toggle-button {
          .mat-button-toggle-label-content {
            line-height: 32px;
          }
        }
      }
    }
  }

  .non-disabled {
    mat-form-field.mat-form-field {
      &.mat-form-field-appearance-fill {
        &.mat-form-field-disabled {
          opacity: 1 !important;

          .mat-form-field-wrapper {
            .mat-form-field-flex {
              border: 1px solid #ccc;

              .mat-form-field-infix {
                .mat-input-element {
                  --tw-text-opacity: 1 !important;
                  color: rgba(var(--fuse-text-secondary-rgb), var(--tw-text-opacity)) !important;
                }
              }
            }
          }
        }
      }
    }
  }

  popper-content {
    .ngxp__container {
      display: block !important;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      background-color: white;

      &[aria-hidden='true'] {
        visibility: hidden;
      }
    }
  }
}
