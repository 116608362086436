@mixin scroll() {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background: #b6becb;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #cbd5e1;
  }

  scrollbar-color: #e2e8f0 #ffffff;
  scrollbar-width: thin;
}
