.committees-app {
  .break-word {
    word-break: break-word;
  }

  .break-all {
    word-break: break-all;
  }

  .rounded-box {
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 16px;
  }

  .ql-editable-blot {
    display: inline-block;
    background-color: #e2e8f0;
    padding: 2px 12px;
    margin: 1px;
    border-radius: 16px;
  }

  .icon-disabled {
    color: #aaa !important;
  }

  .cdk-drop-list-dragging {
    cursor: grabbing;
  }

  mat-form-field.mat-form-field.mat-form-field-appearance-fill {
    &.icon-select {
      .mat-form-field-wrapper .mat-form-field-flex {
        padding: 0 3px;

        .mat-form-field-infix {
          width: 40px;
        }
      }
    }
  }
}
